html, body, #root, .App {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

@font-face {
    font-family: Montserrat-light;
    src: url("../fonts/Montserrat/Montserrat-Regular.ttf");
}

* {
    font-family: "Montserrat-light", sans-serif;
    transition: all .2s;
}

a {
    cursor: pointer;
    margin: 0;
}